import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Button, Chip, CircularProgress, Fade } from "@mui/material";
import FilterPopup from "./DataTableFilter";
import { FilterProps } from "../pages/ListPage";

export interface SnapperDomain {
  strDomainName: string;
  strRegistrar: string;
  strStatus: string;
  strExpiryDate: string;
  strSuspendDate: string;
  strRedemptionDate: string;
  strDeleteDate: string;
  bAutorenew: boolean;
  bSnap: boolean;
  snapped: string;
  strSnapDate: string;
}

export type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof SnapperDomain;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "strDomainName",
    numeric: false,
    disablePadding: true,
    label: "Domain Name"
  },
  {
    id: "bAutorenew",
    numeric: true,
    disablePadding: false,
    label: "Autorenew"
  },
  {
    id: "strRegistrar",
    numeric: true,
    disablePadding: false,
    label: "Registrar"
  },
  {
    id: "strExpiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry Date"
  },
  {
    id: "strDeleteDate",
    numeric: true,
    disablePadding: false,
    label: "Deletion Date"
  },
  {
    id: "bSnap",
    numeric: true,
    disablePadding: false,
    label: "Snap"
  },
  {
    id: "snapped",
    numeric: true,
    disablePadding: false,
    label: "Snapped"
  },
  {
    id: "strSnapDate",
    numeric: true,
    disablePadding: false,
    label: "Snapped Date"
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SnapperDomain
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler =
    (property: keyof SnapperDomain) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all domains"
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "snapped" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id
                    ? order === "asc"
                      ? "desc"
                      : "asc"
                    : "desc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: any;
  set_cancel_grab: (names: string[]) => void;
  set_grab: (names: string[]) => void;
  loading: boolean;
  filterOpen: boolean;
  handle_filter_open: () => void;
  handle_filter_close: () => void;
  exportSelectedItems: () => void;
  handle_apply_filter: (filter_props: FilterProps) => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    selected,
    set_cancel_grab,
    set_grab,
    loading,
    handle_filter_open,
    handle_filter_close,
    filterOpen,
    handle_apply_filter,
    exportSelectedItems
  } = props;
  const handleCancelSnap = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    set_cancel_grab(selected);
  };
  const handleSnap = async (event: React.MouseEvent<HTMLButtonElement>) => {
    set_grab(selected);
  };
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        }
      ]}
    >
      <FilterPopup
        onApply={handle_apply_filter}
        open={filterOpen}
        setClose={handle_filter_close}
      />
      {numSelected > 0 ? (
        <React.Fragment>
          <Typography
            sx={{ flex: "auto" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          {loading ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              component="div"
              mr={8}
            >
              Loading...
            </Typography>
          ) : (
            <React.Fragment>
              <Button
                size="small"
                onClick={exportSelectedItems}
                variant="contained"
                color="primary"
                style={{
                  whiteSpace: "nowrap",
                  width: "fit-content",
                  marginRight: 16,
                  minWidth: 220
                }}
              >
                Export Selected as CSV
              </Button>
              <Button
                size="small"
                onClick={handleSnap}
                variant="contained"
                style={{
                  whiteSpace: "nowrap",
                  width: "fit-content",
                  marginRight: 16
                }}
              >
                Snap
              </Button>
              <Button
                size="small"
                onClick={handleCancelSnap}
                variant="contained"
                color="warning"
                style={{ whiteSpace: "nowrap", width: "140px" }}
              >
                Cancel Snap
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <></>
      )}
      {numSelected === 0 && (
        <Tooltip title="Filter list">
          <IconButton onClick={handle_filter_open}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}
const DataTable: React.FC<{
  data: SnapperDomain[];
  set_cancel_grab: (name: string[]) => void;
  set_grab: (name: string[]) => void;
  handle_apply_filter: (filter_props: FilterProps) => void;
  loading: boolean;
  page: number;
  amount: number;
  setPage: (page: number) => void;
  setAmount: (amount: number) => void;
  filterTotal: number;
  order: Order;
  orderBy: keyof SnapperDomain;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof SnapperDomain) => void;
  selected: string[];
  setSelected: (selected: string[]) => void;
  exportSelectedItems: () => void;
}> = ({
  data,
  set_cancel_grab,
  set_grab,
  handle_apply_filter,
  loading,
  page,
  setPage,
  amount,
  setAmount,
  filterTotal,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  selected,
  setSelected,
  exportSelectedItems
}) => {
  const [dense, setDense] = React.useState(true);
  const [filterOpen, setFilterOpen] = React.useState(false);

  const handle_filter_open = () => {
    setFilterOpen(true);
  };

  const handle_filter_close = () => {
    setFilterOpen(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SnapperDomain
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.strDomainName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    strDomainName: string
  ) => {
    const selectedIndex = selected.indexOf(strDomainName);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, strDomainName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (strDomainName: string) =>
    selected.indexOf(strDomainName) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, amount - data.length) : 0;

  const visibleRows = React.useMemo(() => [...data], [data]);

  return (
    <Box sx={{ width: "auto", mt: 4, ml: 2, mr: 2 }}>
      <Paper elevation={2} sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          set_cancel_grab={set_cancel_grab}
          set_grab={set_grab}
          loading={loading}
          handle_filter_open={handle_filter_open}
          handle_filter_close={handle_filter_close}
          filterOpen={filterOpen}
          handle_apply_filter={handle_apply_filter}
          exportSelectedItems={exportSelectedItems}
        />

        <TableContainer
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            position: "relative"
          }}
        >
          <Fade in={!loading} timeout={300}>
            <Table
              stickyHeader
              sx={{ minWidth: 750, minHeight: 53 }}
              aria-labelledby="SnapperList"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.strDomainName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.strDomainName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.strDomainName}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.strDomainName}
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          label={row.bAutorenew ? "Enabled" : "Disabled"}
                          color={row.bAutorenew ? "warning" : "success"}
                          variant="outlined"
                          size={dense ? "small" : "medium"}
                        />
                      </TableCell>
                      <TableCell align="right">{row.strRegistrar}</TableCell>
                      <TableCell align="right">{row.strExpiryDate}</TableCell>
                      <TableCell align="right">{row.strDeleteDate}</TableCell>
                      <TableCell align="right">
                        <Chip
                          label={row.bSnap ? "Yes" : "No"}
                          color={row.bSnap ? "success" : "warning"}
                          variant="outlined"
                          size={dense ? "small" : "medium"}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          label={row.strSnapDate ? "Yes" : "No"}
                          color={row.strSnapDate ? "success" : "warning"}
                          variant="outlined"
                          size={dense ? "small" : "medium"}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {row.strSnapDate ? row.strSnapDate : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Fade>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1
              }}
            >
              <CircularProgress />
            </div>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500]}
          component="div"
          count={filterTotal}
          rowsPerPage={amount}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeAmount}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Compact"
      />
    </Box>
  );
};

export default DataTable;
