import axios from "axios";
import { SearchFilters } from "./pages/ListPage";

export const login = async (username: string, password: string) => {
  return await axios
    .post(
      "https://snapper.domains.co.za/api.php",
      { function: "login", fields: { username, password } },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response.data;
    });
};

export const fetchStats = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    "https://snapper.domains.co.za/api.php",
    {
      function: "stats",
      token,
      fields: {}
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response?.data;
};

export const fetchDomains = async (filters: SearchFilters) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    "https://snapper.domains.co.za/api.php",
    { function: "fetchDomains", token, fields: filters },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response.data;
};

export const bulkGrab = async (names: string[]) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    "https://snapper.domains.co.za/api.php",
    {
      function: "bulkGrab",
      token,
      fields: {
        name: names
      }
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response?.data;
};

export const grab = async (name: string) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    "https://snapper.domains.co.za/api.php",
    {
      function: "grab",
      token,
      fields: { name }
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response?.data;
};

export const cancelGrab = async (name: string) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    "https://snapper.domains.co.za/api.php",
    {
      function: "cancelGrab",
      token,
      fields: { name }
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response?.data;
};
